<template>
  <div>
    <el-breadcrumb style="margin: 0 0 20px 10px" separator="/">
      <el-breadcrumb-item :to="{ path: '/welcome' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>客户管理</el-breadcrumb-item>
      <el-breadcrumb-item>客户列表</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="content">
      <!-- <div class="tt">危废管理</div>
      <div class="xian"></div> -->
      <div class="list_box">
        <div class="tt1">客户列表</div>
        <div>
          <el-button type="add" icon="el-icon-circle-plus-outline" @click="add"
            >添加</el-button
          >
        </div>
      </div>
      <div class="int_box">
        <div class="int_box11">
          <span>客户名称：</span>
          <el-input
            class="int_width"
            v-model="name"
            placeholder="请输入客户名称"
            clearable
          ></el-input>
          <el-button
            style="margin-left: 50px"
            type="cha"
            icon="el-icon-search"
            @click="chaxun"
            >查询</el-button
          >
        </div>
      </div>
      <template>
        <el-table
          :header-cell-style="tableHeader"
          :data="userList"
          style="width: 100%"
          stripe
        >
          <el-table-column
            align="center"
            prop="client_name"
            label="客户名称"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="url"
            label="地址"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="principal"
            label="负责人"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="principal_mobile"
            label="负责人电话"
          ></el-table-column>
          <el-table-column width="200px" align="center" label="操作">
            <template v-slot="scope">
              <el-button
                size="mini"
                type="primary"
                icon="iconfont icon-bianxie"
                @click="edit(scope.row.id)"
                >编辑</el-button
              >
              <el-button
                size="mini"
                type="danger"
                icon="iconfont icon-shanchu"
                @click="del(scope.row.id)"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </template>
      <div class="pagination">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="page"
          :page-sizes="[5, 10, 15, 20]"
          :page-size="pageNumber"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        ></el-pagination>
      </div>
      <!-- 添加 -->
      <el-dialog
        :title="text"
        :close-on-click-modal="false"
        :visible.sync="dialogVisible1"
        width="1100px"
        :before-close="handleClose1"
      >
        <div class="xian2"></div>
        <el-form
          ref="form1"
          :rules="rules1"
          :model="addform1"
          label-width="200px"
        >
          <el-form-item label="客户名称：" prop="name">
            <el-input class="int" v-model="addform1.name"></el-input>
          </el-form-item>
          <el-form-item label="地址：" prop="url">
            <el-input class="int" v-model="addform1.url"></el-input>
          </el-form-item>
          <el-form-item label="负责人：" prop="principal">
            <el-input class="int" v-model="addform1.principal"></el-input>
          </el-form-item>
          <el-form-item label="手机号：" prop="principal_mobile">
            <el-input class="int" v-model="addform1.principal_mobile"></el-input>
          </el-form-item>
            <el-form-item label="备注：">
              <el-input type="textarea" class="int" :autosize="{ minRows: 3}" v-model="addform1.remark"></el-input>
            </el-form-item>
        </el-form>
        <div class="btn_box">
          <el-button type="cha" class="add_btn111" @click="addRuku"
            >确定</el-button
          >
        </div>
      </el-dialog>
      
    </div>
  </div>
</template>
<script>
export default {
    
  data() {
       var checkMobile = (rule, value, cb) => {
      const regMobile = /^1[3456789]\d{9}$/
      if (regMobile.test(value)) {
        return cb()
      }
      cb(new Error('请输入合法的手机号'))
    }
    return {
      page: 1,
      pageNumber: 5,
      name: '',
      userList: [],
      total: 0,
      tableHeader: {
        'background-color': '#EEF1F6',
        'border-top': '2px solid #1CA6C8',
        color: '#333',
      },
      

      // 使用配置参数
      dialogVisible1: false,
      addform1: {
        name: '',
        url: '',
        principal: '',
        principal_mobile: '',
        remark: ''
      },
      rules1: {
        name: [
          { required: true, message: '请输入客户名称', trigger: 'blur' },
        ],
        url: [{ required: true, message: '请输入地址', trigger: 'blur' }],
        principal: [{ required: true, message: '请输入负责人', trigger: 'blur' }],
        principal_mobile: [{ required: true, message: '请输入手机号', trigger: 'blur' },{ validator: checkMobile, trigger: 'blur' }]
      },
      text:'添加'
    }
  },
  created() {
    this.user()
  },
  methods: {
    handleSizeChange(val) {
      this.pageNumber = val
      this.user()
    },
    handleCurrentChange(val) {
      this.page = val
      this.user()
    },
    
    handleClose1() {
      this.dialogVisible1 = false
      this.$refs.form1.resetFields()
      this.addform = {
        name: '',
        url: '',
        principal: '',
        principal_mobile: '',
        remark: '',
        id: ''
      }
    },
    

    // 列表
    async user() {
      const { data: res } = await this.$http.get('api/client/index', {
        params: {
          page: this.page,
          pageNumber: this.pageNumber,
          name: this.name
        },
      })
      console.log(11, res)
      this.userList = res.data.rows
      this.total = res.data.total
    },
    // 查询
    chaxun() {
      this.page = 1
      this.user()
    },
    
    // 点击添加
    add() {
      this.dialogVisible1 = true
      this.text = '添加'
    },
    
   
    // 添加
    addRuku() {
      this.$refs.form1.validate(async (valid) => {
        if (!valid) return this.$message.error('请填写完整的数据')

        const { data: res } = await this.$http.post(
          'api/client/saves',
          this.addform1
        )
        if (res.code != 200) return this.$message.error(res.msg)
        this.$message.success(res.msg)
        this.dialogVisible1 = false
        this.$refs.form1.resetFields()
        this.page = 1
        this.user()
      })
    },
    // 点击编辑
    edit(id) {
        this.text = '编辑'
        this.editMessage(id)
        this.dialogVisible1 = true
    },
    // 编辑获取信息
    async editMessage(id) {
         const { data: res } = await this.$http.post('api/client/getMessage', 
       {
         id: id
        },
      )
      console.log(11, res)
       if (res.code != 200) return this.$message.error(res.msg)
        this.addform1= {
        name: res.data.client_name,
        url: res.data.url,
        principal: res.data.principal,
        principal_mobile: res.data.principal_mobile,
        remark: res.data.remark,
        id: res.data.id
      }
      
     
    },
    // 删除
    del(id) {
        this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(async () => {
            const { data: res } = await this.$http.post(
          'api/client/delete',
          {id: id}
        )
        if (res.code != 200) return this.$message.error(res.msg)
        this.$message.success(res.msg)
        this.page = 1
        this.user()
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });          
        });
    }
  },
}
</script>
<style lang="less" scoped>
.content {
  width: 100%;
  background-color: #fff;
  padding: 30px;
  box-sizing: border-box;
  margin-bottom: 20px;
  .tt {
    font-size: 24px;
  }
  .xian {
    width: 100%;
    height: 2px;
    background-color: #1ca6c8;
    margin: 30px auto;
  }
  .list_box {
    width: 100%;
    height: 60px;
    background-color: #f0f0f0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 10px;
    box-sizing: border-box;
    .tt1 {
      font-size: 18px;
      padding: 4px 10px;
      border-left: 3px solid #1ca6c8;
    }
  }
  .int_box {
    padding: 25px 1px 0;
    display: flex;
    flex-wrap: wrap;
  }
  .int_box11 {
    display: flex;
    align-items: center;
    margin-bottom: 25px;
  }
  .int_width {
    width: 300px;
    margin-right: 40px;
  }
  .int_width1 {
    width: 300px;
  }
  span {
    color: #666;
  }
  .pagination {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding: 20px 0 10px;
  }
  .xian2 {
    width: 100%;
    height: 2px;
    background-color: #f0f0f0;
    margin-bottom: 20px;
  }
  .int {
    width: 400px;
  }

  .add_btn {
    margin-left: 800px;
  }
  .danger_btn {
    margin-left: 20px;
  }
  .btn_box {
    width: 100%;
    text-align: center;
    padding: 30px 0;
  }
  .add_btn111 {
    width: 140px;
  }
}
</style>
